<template>
  <div data-app>
    <div class="container-fluid">
      <!-- 1°first ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 1°first Card -->
          <div class="card">
            <div class="card-header">
              Ubicación
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-info badge-pill float-right sdw">
                    <i class="fas fa-map text-white"></i>
                  </span>
                </template>
                <span>Detalles Ubicación</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-danger badge-pill float-right sdw mr-3 pointer"
                    @click="eliminate()"
                  >
                    <i class="fas fa-trash text-white"></i>
                  </span>
                </template>
                <span>ELiminar Ubicación</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-warning badge-pill float-right sdw mr-3 pointer"
                    @click="edit()"
                  >
                    <i class="fas fa-edit text-dark"></i>
                  </span>
                </template>
                <span>Editar Ubicación</span>
              </v-tooltip>
            </div>
            <div class="card-body">
              <v-container>
                <!-- 2°second ROW -->
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <ul class="list-group mb-3">
                      <h4 class="d-flex justify-content-between align-items-center mb-3">
                        <span class="text-muted">{{location.code}}</span>
                        <span class="badge badge-secondary badge-pill">ID: {{id}}</span>
                      </h4>
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0">Codigo: {{location.code}}</h6>
                          <small class="text-muted">Codigo de Ubicación.</small>
                        </div>
                        <div>
                          <span class="badge badge-secondary badge-pill float-right">
                            <i class="fas fa-map"></i>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
                <!--Dialog--->
                <v-dialog v-model="dialog" width="800" persistent>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-card>
                          <v-card-title>Edítar Ubicación</v-card-title>
                          <v-divider></v-divider>
                          <v-container>
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="6" md="2">
                                  <v-text-field
                                    v-model="location.id"
                                    name="id"
                                    readonly="readonly"
                                    label="ID"
                                    filled
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="9" md="6">
                                  <v-text-field v-model="location.code" label="Codigo"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-select
                                    v-model="location.warehouse_id"
                                    :items="warehouses"
                                    :rules="[v => !!v || 'Seleccionar Una Bodega, es requerido!']"
                                    item-value="id"
                                    item-text="name"
                                    label="Bodegas"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                              color="#FFD700"
                              class="mx-2"
                              fab
                              dark
                              small
                              @click="editLocation()"
                            >
                              <v-icon color="#000000" dark>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="#FF6347"
                              @click="dialog = false"
                            >
                              <v-icon dark>mdi-close</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-dialog>
                <!--End Dialog--->
              </v-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: ["id"],
  data: () => ({
    boolean: false,
    dialog: false,
    valid: true,
    location: {
      id: 0,
      code: "def",
      warehouse_id: 0,
    },
    warehouses: [],
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
    defaultRequiredRules: [
      (v) => !!v || "Este campo es obligatorio",
      // (v) => v.length >= 3 || " Minimo de 3 caracters",
    ],
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "➀ Localización", route: "/inventario/locations" },
      {
        title: "➁ Detalle Localización",
        route: `/inventario/locations/${this.id}`,
      },
    ]);
    this.fetchLocation();
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    close() {
      this.dialog = false;
    },
    edit() {
      this.fetchWarehouses();

      this.dialog = true;
    },
    editLocation() {
      var vm = this;

      if (!vm.location.code.length || vm.location.code == null) {
        vm.$bvToast.toast("El Codígo de la Ubicación no debe ir vacío!", {
          title: `Formulario Invalido`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-bottom-center",
        });
        vm.$refs.form.$el[1].focus();
        return;
      }

      if (vm.location.warehouse_id === 0 || isNaN(vm.location.warehouse_id)) {
        vm.$bvToast.toast("Seleccione una Bodega porfavor", {
          title: `Formulario Invalido`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-bottom-center",
        });
        vm.$refs.form.$el[2].focus();
        return;
      }

      if (this.$refs.form.validate()) {
        this.axios({
          url: "inventory/locations/" + vm.id,
          method: "PUT",
          data: {
            code: vm.location.code,
            default: vm.location.default,
            warehouse_id: vm.location.warehouse_id,
          },
        })
          .then((response) => {
            vm.fetchLocation();
            vm.$bvToast.toast(
              `La Ubicación, codígo: ${vm.location.code} ha sido actualizada el ${this.dateMsg} exitosamente!`,
              {
                title: `Información`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-bottom-center",
              }
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }

      this.close();
    },
    fetchLocation() {
      var vm = this;
      this.axios({
        url: "inventory/locations/" + vm.id,
        method: "GET",
      })
        .then((response) => {
          console.log(response.data);
          vm.location = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchWarehouses() {
      var vm = this;
      this.axios({
        url: "inventory/warehouse/by_store",
        method: "GET",
      })
        .then((response) => {
          vm.warehouses = response.data.data;
          vm.selecCurrentWarehouse();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selecCurrentWarehouse() {
      var vm = this;
      console.log(vm.warehouses);
      var cwh = [];
      vm.warehouses.forEach((element) => {
        console.log(vm.location.warehouse_id);
        if (element.id == vm.location.warehouse_id) {
          // Mientras no se puede agregar stock a otra bodega
          cwh.push(element);
        }
      });

      vm.warehouses = cwh;
    },
    eliminate() {
      var vm = this;

      const confirmacion = confirm(
        `Esta seguro de eliminar la Ubicación: ${vm.location.code} || ID: ${vm.id}?`
      );
      if (confirmacion) {
        //set to localStorage to persist alert data on the redirected route
        localStorage.setItem(
          "deleteLocation",
          JSON.stringify({
            id: vm.id,
            code: vm.location.code,
          })
        );

        this.axios({
          url: "inventory/locations/" + vm.id,
          method: "DELETE",
        })
          .then((response) => {
            vm.$bvToast.toast(
              `La Ubicación: ${vm.location.code} ha sido eliminada, el ${vm.dateMsg} exitosamente!`,
              {
                title: `Información`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-bottom-center",
              }
            );
            //rediect to locations
            vm.$router.push("/inventario/locations");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>